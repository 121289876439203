import React, { useContext } from 'react';
import {  Link } from "react-router-dom";
import Hamburger from "./Hamburger.js";
import { useState } from 'react';
import logo from './kalto_logo.svg';
import { HashLink } from "react-router-hash-link";

const Navbar = () =>{

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

      const toggleHamburger = () =>{
          setHamburgerOpen(!hamburgerOpen)
      }

      return(
          <div>

            <nav className ="navbar navigation container">
              <div className="hamburger" onClick={toggleHamburger}>
                  <Hamburger isOpen={hamburgerOpen}/>
              </div>

              <a href="/"><img class="logo" src={logo} alt="kalto.cz"/></a>

              <ul className ="navbar-content">
                <li className ="nav-item">
                  <HashLink smooth to="/#services">Naše služby</HashLink>
                </li>
                <li className ="nav-item">
                  <HashLink smooth to="/#about">O nás</HashLink>
                </li>
                <li className ="nav-item">
                  <HashLink smooth to="/#contact">Kontakt</HashLink>
                </li>
              </ul>
            </nav>

              <style jsx>{`
                  .navigation{
                      width: 100%;
                      height: 50px;
                      display: flex;
                      flex-direction: row;
                      padding-top:20px;
                  }

                  .navigation ul{
                      display:flex;
                      flex-wrap: wrap;
                      float: right;
                      flex-direction: row;
                      justify-content: end;
                      width: 100%;
                      margin: 0px;
                      padding: 0px;
                      overflow: hidden;
                      gap:30px;
                      flex-basis: 70%;
                  }
                  .navigation ul li{
                      list-style-type: none;
                      padding-right: 10px;
                  }
                  .navigation li:hover a{
                    color: #00558B;
                  }
                  .navigation li a{
                      color: #000000;
                      font-size: 1.3rem;

                  }
                  .hamburger{
                      display: none;
                      z-index: 6;
                  }
                  @media (max-width: 767px){

                      .hamburger{
                          display: flex;
                          gap: 5px;
                          margin-left: 10px;
                          z-index: 6;
                      }

                      .navigation{
                        height: ${hamburgerOpen ? '100%' : '50px'};
                      }

                      .navigation ul{
                          display: ${hamburgerOpen ? 'flex' : 'none'};

                              flex-direction: column;
                              margin-top: 20px;
                              align-items: center;
                              justify-content: center;
                              flex-basis: unset;

                      }
                      .navigation img{
                        margin: auto;
                        padding-right: 32px;
                      }
                  }



              `}</style>
          </div>
      );
}
export default Navbar;
