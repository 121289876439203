import homepage_logo from './kalto_homepage.svg';
import handshake from './handshake.svg';
import love from './love.svg';
import comunication from './comunication.svg';
import contact from './contact.svg';
import mail from './mail.svg';
import phone from './phone.svg';
import tiktok from './tiktok.svg';
import marketing from './market.svg';
import cart from './cart.svg';


const Home = () => {


    return (
        <div className="home container">

            <div className="header">
              <h1 className="f-w-700">S námi Vaše</h1>
              <h1 className="color-blue f-w-700">tržby vyletí !</h1>
              <h3 className="margin-t-20">Našim cílem je zvýšení prodejnosti a atraktivnosti produktů.</h3>
              <h3>Váš růst, naše radost!</h3>
              <img src={homepage_logo} alt="homepage_logo"/>
            </div>

            <div className="main-content">

            <div className="services margin-100" id="services">
              <h2 className="f-w-700">Naše služby</h2>
              <div className="content margin-t-20">

                <div className="s-card">
                  <img src={marketing} alt="p-card-img" />
                  <h3>Marketingové odkazy</h3>
                  <span>Marketingové bannery sloužící pro vložení slevového kódu.</span>
                </div>

                <div className="s-card">
                  <img src={cart} alt="p-card-img" />
                  <h3>Hromadné vkládání do košíku</h3>
                  <span>Umožňujeme hromadné vkládání produktů pomocí jednoho kliknutí.</span>
                </div>

                <div className="s-card">
                  <img src={tiktok} alt="p-card-img" />
                  <h3>TikTok banner</h3>
                  <span>Umožňujeme zobrazení tiktok feedu.</span>
                </div>

              </div>
            </div>

              <div className="why-us margin-100" id="about">
                <h2 className="f-w-700">Proč si zvolit nás?</h2>
                <div className="pillars margin-t-20">

                  <div className="p-card">
                    <img src={comunication} alt="p-card-img" />
                    <h3>Rychlá komunikace</h3>
                    <span>Snažíme se, aby komunikace nevázla. Věříme, že v dobré komunikaci tkví úspěch.</span>
                  </div>

                  <div className="p-card">
                    <img src={handshake} alt="p-card-img" />
                    <h3>Férové jednání</h3>
                    <span>S námi vše sjednané platí! Jelikož víme, že čas jsou peníze. </span>
                  </div>

                  <div className="p-card">
                    <img src={love} alt="p-card-img" />
                    <h3>Záruka spokojenosti</h3>
                    <span>Záleží nám na spokojenosti. Spokojený zakázník, stálý zákazník.</span>
                  </div>

                </div>
              </div>

              <div className="contact margin-100" id="contact">
                  <div className="content left">
                    <h2 className="f-w-700">Kontaktujte nás</h2>
                    <h6>Neváhejte nás kdykoliv a ohledně čehokoliv kontaktovat</h6>

                    <div className="info email">
                      <img  className="icon" src={mail} alt="mail"/>
                      <a href="mailto:info@kalto.cz">info@kalto.cz</a>
                    </div>
                    <div className="info phone">
                      <img className="icon" src={phone} alt="phone"/>
                      <a href="tel:+420 603 738 837">+420 603 738 837</a>
                    </div>
                  </div>
                  <div className="content right">
                    <img src={contact} />
                  </div>
              </div>

                <div className="footer">

                </div>
            </div>
        </div>
    );
}

export default Home;
